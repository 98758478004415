import { navigate } from 'gatsby'
import firebase from 'gatsby-plugin-firebase'
import { saveAs } from 'file-saver'

const isBrowser = typeof window !== 'undefined' && !window.STORYBOOK_ENV

if (isBrowser) {
    firebase.firestore().settings({ experimentalForceLongPolling: true, merge: true })
}

const submitStandardForm = async (options) => {
    try {
        await firebase
            .firestore()
            .collection(options.firebaseCollection)
            .add({
                to: options.confirmationEmail,
                template: {
                    name: options.emailTemplate,
                    data: options.firebaseData
                }
            })

        options.actions.setSubmitting(false)

        if (options.fileDownload) {
            const saveFile = () => {
                saveAs(options.fileDownload.location, options.fileDownload.fileName)
            }

            saveFile()
        }

        if (options.redirect) {
            navigate(options.redirect)
        }
    } catch (err) {
        console.error('Error with submitting the form:', err)
    }
}

export default submitStandardForm
